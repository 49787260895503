export const SRO_CODES = {
    DELIVERED: '1',
    NOT_WANTED: '3',
    REFUSED: '4',
    DECEADSED: '5',
    UNKNOWN: '6',
    INSUFICIENTE_ADDRESS: '7',
    NUMBER_NOT_FOUND: '8',
    MOVED: '10',
    ABSENT_RETURNED_TO_SENDER: '21',
    NOT_WANTED_RETURNED_TO_SENDER: '26',
    DAMAGED: '28',
    DOCUMENTATION_NOT_PROVIDED: '33',
    SINISTER: '37',
    BANKRUPT: '38',
    ADDRESS_WITHOUT_HOME_DISTRIBUTION: '48',
    STOLEN: '50',
    STOLEN2: '51',
    STOLEN3: '52',
    LOST: '80',
    MATCH_ERROR: '',
};

export const parseSroMessage = sro => {
  switch (sro) {
    case SRO_CODES.DELIVERED:
      return 'Entregue';
    case SRO_CODES.NOT_WANTED:
      return 'Não procurado';
    case SRO_CODES.REFUSED:
      return 'Recusado';
    case SRO_CODES.DECEADSED:
      return 'Falecido';
    case SRO_CODES.UNKNOWN:
      return 'Desconhecido';
    case SRO_CODES.INSUFICIENTE_ADDRESS:
      return 'Endereço insuficiente';
    case SRO_CODES.NUMBER_NOT_FOUND:
      return 'Não existe o número indicado';
    case SRO_CODES.MOVED:
      return 'Mudou-se';
    case SRO_CODES.ABSENT_RETURNED_TO_SENDER:
      return 'Ausente - Devolvido ao remetente';
    case SRO_CODES.NOT_WANTED_RETURNED_TO_SENDER:
      return 'Não procurado - Devolvido ao remetente';
    case SRO_CODES.DAMAGED:
      return 'Avariado';
    case SRO_CODES.DOCUMENTATION_NOT_PROVIDED:
      return 'Documentação não fornecida';
    case SRO_CODES.SINISTER:
      return 'Sinistro';
    case SRO_CODES.BANKRUPT:
      return 'Empresa falida';
    case SRO_CODES.ADDRESS_WITHOUT_HOME_DISTRIBUTION:
      return 'Endereço sem distribuição domiciliar';
    case SRO_CODES.STOLEN:
      return 'Roubado';
    case SRO_CODES.STOLEN2:
      return 'Roubado';
    case SRO_CODES.STOLEN3:
      return 'Roubado';
    case SRO_CODES.LOST:
      return 'Objeto extraviado';
    case SRO_CODES.MATCH_ERROR:
      return 'Cógido não identificado';
  }
};

export const parseServiceMessage = arStep => {
  return arStep.inconsistencyType ? arStep.content : '';
};