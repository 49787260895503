import { useState, useCallback } from 'react';

export function useIsTruncated() {
  const [isTruncated, setIsTruncated] = useState(false);

  const ref = useCallback((domElement) => {
    if (!domElement) return;

    const contentIsTruncated = domElement.scrollWidth > domElement.clientWidth;
    setIsTruncated(contentIsTruncated);
  }, []);

  return { ref, isTruncated };
}
