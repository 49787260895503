export const PermissionResources = {
  AGREEMENT: 'inquiries/agreement',
  ANALYSIS: 'inquiries/analysis',
  APFD_DEADLINE_REPORT: 'report/apfd-deadline',
  APFD_CUSTODY_REPORT: 'report/apfd-custody',
  ARCHIVE: 'inquiries/archive',
  ASSOCIATIONS: 'associations',
  AUDIENCE: 'inquiries/audience',
  BATCH_DISTRIBUTION: 'batch-distribution',
  CRIMES: 'crimes',
  DECLINE: 'inquiries/decline',
  DENUNCIATION: 'inquiries/denunciation',
  DISCHARGE: 'discharge',
  DISTRIBUTION: 'inquiries/distribution',
  DISTRIBUTIONS_AND_MOVEMENTS_REPORT: 'report/distributions-and-movements',
  DELETE_AUDIENCE: 'delete-audience',
  EXTERNAL_CREDENTIALS_CONFIG: 'configuration/external-credentials',
  FORWARD_DUE_DILIGENCE: 'inquiries/forward_due_diligence',
  FORWARDING_TERM: 'inquiries/forwarding-term',
  INQUIRIES: 'inquiries',
  INQUIRY_REPORT: 'report/inquiry',
  INTIMATION: 'inquiries/intimation',
  INTIMATION_MODE_CONFIG: 'configuration/intimation-modes',
  INTIMATION_VIEW: 'intimation-view',
  AUDIT_LOG_VIEW: 'audit-log-view',
  MATERIAL_AND_COMMUNITY_SERVICE: 'material-and-community-service',
  ORGANS: 'organs',
  OUT_OF_DATE_REQUESTS_REPORT: 'report/out-of-date-requests',
  OVERVIEW_EXCEL: 'overview-excel',
  PENDING_AND_REGISTERED_REPORT: 'report/pending-and-registered',
  PENDING_OR_TRIAGE_PROCEDURES_REPORT: 'report/pending-or-triage-procedures',
  PERSECUTION: 'inquiries/persecution',
  PROCESSING: 'inquiries/processing',
  PROJECTS: 'projects',
  PROSECUTOR_OFFICE_GROUP_PERFORMANCE_REPORT: 'report/prosecutor-office-group-performance',
  PROSECUTOR_OFFICE_GROUPS: 'prosecutor-office-groups',
  PROSECUTOR_OFFICE_PERFORMANCE_REPORT: 'report/prosecutor-office-performance',
  PCPE_INTEGRATION_REPORT: 'report/pcpe-integration',
  PROCEDURE_AUDIT_REPORT: 'report/procedure-audit',
  USER_AUDIT_REPORT: 'report/user-audit',
  PROSECUTOR_OFFICE_BALANCE_REPORT: 'report/prosecutor-office-balance',
  ERROR_IMPORT_ARQUIMEDES_INTEGRATION_REPORT_VIEW: 'report/error-import-arquimedes-integration',
  INQUIRIES_MIGRATION_REPORT: 'report/inquiries-migration',
  RECEIVED_DOCUMENTS: 'received-documents',
  REGISTER_INQUIRIES: 'register/inquiries',
  RESPONSE_OF_SOLICITATION: 'inquiries/response-of-solicitation',
  RETURNED: 'inquiries/returned',
  RETURNED_PROCEDURES_REPORT: 'report/returned-procedures',
  ROLES: 'roles',
  SCHEDULE: 'schedule',
  SEARCH_INQUIRIES: 'search-inquiries',
  SENT_TO_INTERNAL_SYSTEM: 'inquiries/sent-to-internal-system',
  SENT_TO_JUDICIARY: 'inquiries/sent-to-judiciary',
  SOCIOECONOMIC_DATA_SEARCH: 'socioeconomic-data-search',
  SOLICITATION_MATERIAL_AND_COMMUNITY_SERVICE: 'solicitation-material-and-community_service',
  TRIAGE: 'inquiries/triage',
  UNDISTRIBUTED_INQUIRIES: 'undistributed-inquiries',
  USERS: 'users',
  PROSECUTOR_LIFETIME: 'prosecutor-lifetime',
  INTERNAL_AFFAIRS: 'report/internal-affairs',
  LAWS: 'laws',
  CRIME_SUBJECT: 'crime-subject',
  PARTNER_ASSOCIATIONS: 'partner-associations',
  MANUAL_SYNCHRONIZATION_OF_INVESTIGATED: 'manual-synchronization-of-investigated',
  SEMA_AND_CEMA_ASSOCIATIONS: 'sema-and-cema-associations',
  MIGRATION: 'inquiries/migration',
  DELETE_PENDING_INQUIRY: 'delete-pending-inquiry',
  DELETE_INQUIRY: 'delete-inquiry',
  AI_ASSISTANT: 'ai-assistant',
  AI_AUDIT_REPORT: 'report/ai-audit',
  CONFIDENTIAL_INQUIRY: 'confidential-inquiry',
  VIEW_INQUIRY_OTHER_GROUP_OFFICE: 'inquiries/view-other-group-office',
  BACK_TO_PREVIOUS_STEP: 'back-to-previous-step',
  SEMA_SERVER_ACTIVITIES: 'sema-server-activities',
  BATCH_RETURN: 'batch-return',
  CVLI_REPORT: 'report/cvli',
  UNBOUND_INQUIRY: 'unbound-inquiry',
};
