import { useSelector } from 'react-redux';
import { CUSTOMER_CONFIG } from 'constants/customerConfig';

export const useCustomerConfig = () => {
  const customerConfigs = useSelector(state => state.customerConfig);

  return {
    customerConfigs,
    inquiryManualRegistration: customerConfigs[CUSTOMER_CONFIG.INQUIRY_MANUAL_REGISTRATION],
    inquiryImport: customerConfigs[CUSTOMER_CONFIG.INQUIRY_IMPORT],
    inquiryDisplayCrimes: customerConfigs[CUSTOMER_CONFIG.INQUIRY_DISPLAY_CRIMES],
    inquiryDisplaySubjects: customerConfigs[CUSTOMER_CONFIG.INQUIRY_DISPLAY_SUBJECTS],
    inquiryDisplayInternalSystemNumber:
      customerConfigs[CUSTOMER_CONFIG.INQUIRY_DISPLAY_INTERNAL_SYSTEM_NUMBER],
    displayTriageStep: customerConfigs[CUSTOMER_CONFIG.DISPLAY_TRIAGE_STEP],
    displayDistributionStep: customerConfigs[CUSTOMER_CONFIG.DISPLAY_DISTRIBUTION_STEP],
    enableForwardButton: customerConfigs[CUSTOMER_CONFIG.ENABLE_FORWARD_BUTTON],
    victimIntimation: customerConfigs[CUSTOMER_CONFIG.VICTIM_INTIMATION],
    allOriginNumbersAreNpu: customerConfigs[CUSTOMER_CONFIG.ALL_ORIGIN_NUMBERS_ARE_NPU],
    enableHearingForJuvenileOffenders:
      customerConfigs[CUSTOMER_CONFIG.ENABLE_HEARING_FOR_JUVENILE_OFFENDERS],
    allowManualHml: customerConfigs[CUSTOMER_CONFIG.ALLOW_MANUAL_HML],
    automaticMovements: customerConfigs[CUSTOMER_CONFIG.AUTOMATIC_MOVEMENTS],
    enableRedistributionButton: customerConfigs[CUSTOMER_CONFIG.ENABLE_REDISTRIBUTION_BUTTON],
    viewPcpeIntegrationReport: customerConfigs[CUSTOMER_CONFIG.VIEW_PCPE_INTEGRATION_REPORT],
    viewProcedureAuditReport: customerConfigs[CUSTOMER_CONFIG.VIEW_PROCEDURE_AUDIT_REPORT],
    viewUserAuditReport: customerConfigs[CUSTOMER_CONFIG.VIEW_USER_AUDIT_REPORT],
    allowCancelAgreement: customerConfigs[CUSTOMER_CONFIG.ALLOW_CANCEL_AGREEMENT],
    enablePartnerAssociationRegistration:
      customerConfigs[CUSTOMER_CONFIG.ENABLE_PARTNER_ASSOCIATION_REGISTRATION],
    allowManualSynchronizationOfInvestigated:
      customerConfigs[CUSTOMER_CONFIG.ALLOW_MANUAL_SYNCHRONIZATION_OF_INVESTIGATED],
    viewModalBalances: customerConfigs[CUSTOMER_CONFIG.VIEW_MODAL_BALANCES],
    enableSemaAndCemaAssociationRegistration:
      customerConfigs[CUSTOMER_CONFIG.ENABLE_SEMA_AND_CEMA_ASSOCIATION_REGISTRATION],
    viewMppeIntegrationsInModalVersion:
      customerConfigs[CUSTOMER_CONFIG.VIEW_MPPE_INTEGRATIONS_IN_MODAL_VERSION],
    inquiryDisplayCriminalRecordItem:
      customerConfigs[CUSTOMER_CONFIG.INQUIRY_DISPLAY_CRIMINAL_RECORD_ITEM],
    showGeneralPenalties: customerConfigs[CUSTOMER_CONFIG.SHOW_GENERAL_PENALTIES],
    inquiryDisplayBoundDocuments: customerConfigs[CUSTOMER_CONFIG.INQUIRY_DISPLAY_BOUND_DOCUMENTS],
    inquiryDisplayBoundInquiry: customerConfigs[CUSTOMER_CONFIG.INQUIRY_DISPLAY_BOUND_INQUIRY],
    downloadSplitDocument: customerConfigs[CUSTOMER_CONFIG.DOWNLOAD_SPLIT_DOCUMENT],
    inquiryDisplaySuspectButton: customerConfigs[CUSTOMER_CONFIG.INQUIRY_DISPLAY_SUSPECT_BUTTON],
    isMPDFT: customerConfigs[CUSTOMER_CONFIG.IS_MPDFT],
    showSocioEducationalLecturePenalties:
      customerConfigs[CUSTOMER_CONFIG.SHOW_SOCIOEDUCATIONAL_LECTURE_PENALTIES],
    enablePenaltyTypes: customerConfigs[CUSTOMER_CONFIG.ENABLE_PENALTY_TYPES],
    enableDocAndDocxViewer: customerConfigs[CUSTOMER_CONFIG.ENABLE_DOC_AND_DOCX_VIEWER],
    enableAuditLogView: customerConfigs[CUSTOMER_CONFIG.ENABLE_AUDIT_LOG_VIEW],
    enableRegisterAgreementFromIntimation:
      customerConfigs[CUSTOMER_CONFIG.ENABLE_REGISTER_AGREEMENT_FROM_INTIMATION],
    enableErrorImportArquimedesIntegrationReportView:
      customerConfigs[CUSTOMER_CONFIG.ENABLE_ERROR_IMPORT_ARQUIMEDES_INTEGRATION_REPORT_VIEW],
    enableInquiriesMigrationReport:
      customerConfigs[CUSTOMER_CONFIG.ENABLE_INQUIRIES_MIGRATION_REPORT],
    enableMigratedInquiry: customerConfigs[CUSTOMER_CONFIG.ENABLE_MIGRATED_INQUIRY],
    enablePreRegisterPje: customerConfigs[CUSTOMER_CONFIG.ENABLE_PRE_REGISTER_PJE],
    enableBackToPreviousStep: customerConfigs[CUSTOMER_CONFIG.ENABLE_BACK_TO_PREVIOUS_STEP],
    connectWithAIService: customerConfigs[CUSTOMER_CONFIG.CONNECT_WITH_AI_SERVICE],
    connectWithAIService: customerConfigs[CUSTOMER_CONFIG.CONNECT_WITH_AI_SERVICE],
    enableInstallmentTypes: customerConfigs[CUSTOMER_CONFIG.ENABLE_INSTALLMENT_TYPES],
    enableChangePenaltiesView: customerConfigs[CUSTOMER_CONFIG.ENABLE_CHANGE_PENALTIES_VIEW],
    enableProjectPenaltiesList: customerConfigs[CUSTOMER_CONFIG.ENABLE_PROJECT_PENALTIES_LIST],
    showMaterialsTab: customerConfigs[CUSTOMER_CONFIG.SHOW_MATERIALS_TAB],
    enableSemaServerActivitiesView:
      customerConfigs[CUSTOMER_CONFIG.ENABLE_SEMA_SERVER_ACTIVITIES_VIEW],
    enableSeiProjectService: customerConfigs[CUSTOMER_CONFIG.ENABLE_SEI_PROJECT_SERVICE],
    enablePandoraModule: customerConfigs[CUSTOMER_CONFIG.CONFIG_ENABLE_PANDORA_MODULE],
  };
};
