import { PENALTY_KINDS } from "constants/agreement/penaltyKinds";

const lecturesParser = lectures => {
  const newArrayLectures = [];

  if (lectures?.length > 0) {
    lectures.forEach(item => {
      const newLecture = {
        ...item,
        description: item.description,
        expectedDate: item.expectedDate,
        association: item.association,
        type: item.type,
        penalty: PENALTY_KINDS.SOCIO_EDUCATIVE_LECTURES,
        project: item.project,
      };

      if (item.link) {
        newLecture.link = item.link;
      }

      newArrayLectures.push(newLecture);
    });
  }

  return newArrayLectures;
};

export default lecturesParser;
