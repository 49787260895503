import { INSTALLMENT_TYPES } from 'constants/agreement/installmentTypes';
import { getOnlyNumbers } from './currency';
import { PENALTY_KINDS } from 'constants/agreement/penaltyKinds';

const parcelsHoursSum = parcels => {
  const hours = parcels.map(parcel => parseInt(parcel.hours));
  const total = hours.reduce((accumulator, value) => accumulator + value, 0);
  return total;
};

const parcelsAmountSum = parcels => {
  const amount = parcels.map(parcel => parseInt(parcel.amount));
  const total = amount.reduce((accumulator, value) => accumulator + value, 0);
  return total;
};

const orderParcelsByInitialDate = parcels => {
  return parcels.sort((a, b) => Date.parse(a.initialDate) - Date.parse(b.initialDate));
};

const parcelsSum = parcels => {
  const numbers = parcels.map(parcel => parseInt(getOnlyNumbers(parcel.value)));
  const total = numbers.reduce((accumulator, value) => accumulator + value, 0);
  return total / 100;
};

const parcelsIntegerSum = parcels => {
  const parcelsInt = parcels.map(parcel => parseInt(parcel.value));
  const total = parcelsInt.reduce((accumulator, value) => accumulator + value, 0);
  return total;
};

const paidValue = (parcel, installmentType) => {
  return [INSTALLMENT_TYPES.PAID_BY_SPECIFIC_DATE, INSTALLMENT_TYPES.VARIABLE_PER_PERIOD].includes(
    installmentType,
  )
    ? parseInt(parcel.partiallyPaidValue) || 0
    : parcel.wasPaid && !parcel.paymentsHistory?.length
    ? parseInt(parcel?.value)
    : parseInt(parcel.partiallyPaidValue) || 0;
};

const paidValueSum = (parcels, installmentType, penalty) => {
  const total = parcels.reduce((accumulator, parcel) => {
    return accumulator + paidValue(parcel, installmentType);
  }, 0);
  return [PENALTY_KINDS.SERVICES, PENALTY_KINDS.MATERIALS].includes(penalty)
    ? total
    : (total / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

export {
  parcelsSum,
  parcelsHoursSum,
  parcelsAmountSum,
  orderParcelsByInitialDate,
  parcelsIntegerSum,
  paidValue,
  paidValueSum,
};
