import http from '../httpService';

const IntimationService = {
  addVictimIntimation: params => http.post(`intimations/victim-intimations`, params),

  updateVictimIntimation: (victimIntimationId, params) =>
    http.patch(`intimations/victim-intimations/${victimIntimationId}`, params),

  getVictimIntimation: victimIntimationId =>
    http.get(`intimations/victim-intimations/${victimIntimationId}`),

  uploadVictimIntimationAttachments: (victimIntimationId, params) =>
    http.post(`intimations/victim-intimations/attach/${victimIntimationId}`, params),

  deleteVictimIntimation: victimIntimationId =>
    http.delete(`intimations/victim-intimations/${victimIntimationId}`),

  getIntimations: params => http.get('intimations', { params }),

  getAllIntimationModes: () => http.get('intimations/global-config/modes/types'),

  getIntimationConfirmationCertificates: intimationId =>
    http.get(`intimations/${intimationId}/confirmation-certificates`),

  getIntimationLetter: intimationId => http.get(`intimations/${intimationId}/intimation-letter`),

  cancelIntimation: intimationId => http.put(`intimations/${intimationId}/cancel`),

  createIntimationToHearing: params => http.post(`intimations/intimations-to-hearing`, params),

  confirmManualIntimation: (intimationId, params) =>
    http.post(`intimations/${intimationId}/manual-confirmation`, params),

  outsideIntimation: (inquiryInvestigatedId, params) =>
    http.post(`intimations/outside-intimations/${inquiryInvestigatedId}`, params),

  getArSteps: intimationAttemptId => http.get(`intimations/${intimationAttemptId}/ar-steps`),

  getTrackingCode: intimationAttemptId => http.get(`post-office-api/${intimationAttemptId}/tracking-code`),
};

export default IntimationService;
