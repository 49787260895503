import React, { Children, cloneElement, Component, Fragment } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';
import { Placeholder, PlaceholderSimple, PlaceholderSmall } from './Placeholder';

const styles = {
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  container: {
    display: 'inline-flex',
    flex: '1 1 auto',
  },
  middle: {
    marginRight: '-10px',
  },
};

const renderPlaceholders = props => {
  const { classes, placeholderCounter, variant = 'default', childrenWithProps } = props;

  if (placeholderCounter) {
    const placeholders = [];
    let index = 0;

    while (index < placeholderCounter) {
      switch (variant) {
        case 'small':
          placeholders.push(
            <PlaceholderSmall
              key={index}
              first={index === 0}
              quantity={childrenWithProps.length}
            />,
          );
          break;
        case 'simple':
          placeholders.push(
            <PlaceholderSimple
              key={index}
              first={index === 0}
              quantity={childrenWithProps.length}
            />,
          );
          break;
        default:
          placeholders.push(
            <Grid key={index} className={classes.middle}>
              <Placeholder />
            </Grid>,
          );
          break;
      }

      index++;
    }

    return placeholders;
  }
};

class Timeline extends Component {
  componentDidMount() {
    const scrollbar = this.scrollbar;
    scrollbar.scrollLeft = scrollbar.offsetWidth;
  }

  render() {
    const {
      children,
      classes,
      variant = 'default',
      height = variant === 'small' ? 95 : variant === 'simple' ? 135 : 265,
      placeholderCounter = 0,
      placeholder,
      PerfectScrollbarProps,
    } = this.props;
    const childrenWithProps = children
      ? Children.map(children, (child, index) =>
          cloneElement(child, {
            variant,
            placeholder,
            first: index === 0,
          }),
        )
      : [];
    const placeholders = renderPlaceholders({
      classes,
      placeholderCounter,
      variant,
      childrenWithProps,
    });

    return (
      <Grid className={classes.wrapper} style={{ height }}>
        <PerfectScrollbar
          containerRef={element => (this.scrollbar = element)}
          {...PerfectScrollbarProps}
        >
          <CssBaseline />
          <Grid className={classes.container}>
            <Fragment>
              {childrenWithProps.map((child, index) => {
                return (
                  <Fragment key={index}>
                    <Grid className={classes.middle}>
                      {child}
                    </Grid>
                  </Fragment>
                );
              })}
            </Fragment>
            {placeholderCounter > 0 && placeholder && placeholders}
          </Grid>
        </PerfectScrollbar>
      </Grid>
    );
  }
}

export default withStyles(styles)(Timeline);
