import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Card, Typography, Grid, Tooltip, Box, Divider } from '@material-ui/core';
import Moment from 'react-moment';
import '../../font.css';
import { StepTitle } from '../';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { verifyMaskInquiryNumber } from 'utils/genericFunctions/formatedInquiryNumber';
import colorsSteps from 'utils/colorsSteps';
import {
  DEADLINE_COLORS,
  getColorForDeadline,
} from 'utils/genericFunctions/getColorForTaskDeadline';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import { isInfraction } from 'utils/isInfraction';
import { useIsTruncated } from 'hooks/useIsTruncated';

const CardAgreement = props => {
  const { className, inquiry, ...rest } = props;
  const { allOriginNumbersAreNpu } = useCustomerConfig();

  const useStyles = makeStyles(theme => ({
    root: {
      boxSizing: 'border-box',
      boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 3px 3px 0 rgb(163 163 163)',
      height: '100%',
      width: '100%',
      cursor: 'pointer',
    },
    shadow: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(1, 1, 1, 2),
      boxShadow: `7px 0px 0px 0px ${colorsSteps[inquiry.step]} inset`,
    },
    labelCard: {
      color: '#000',
      fontSize: 14,
      fontFamily: 'roboto-regular',
      textAlign: 'center',
    },
    labelComum: {
      fontFamily: 'roboto-regular',
    },
    classBold: {
      fontWeight: 'bold',
    },
    titleCard: {
      color: '#000',
      fontWeight: 'bold',
      fontSize: 15,
      marginTop: 10,
      fontFamily: 'roboto-regular',
    },
    numeroInterno: {
      color: '#000',
      fontWeight: 'bold',
      fontSize: 13,
      fontFamily: 'roboto-regular',
    },
    cardUserInfo: {
      padding: 5,
      paddingLeft: 10,
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      fontSize: 13,
      fontFamily: 'roboto-regular',
    },
    small: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      backgroundColor: '#000',
      marginTop: theme.spacing(1),
    },
    divScroll: {
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 0,
      },
      maxHeight: 80,
      textAlign: 'left',
    },
    topDivScroll: {
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 0,
      },
      maxHeight: 85,
    },
    divider: {
      backgroundColor: `${colorsSteps[inquiry.step]}`,
    },
    containerType: {
      color: '#000',
      paddingRight: theme.spacing(0.5),
    },
    containerIcon: {},
  }));

  const classes = useStyles();

  const TypographyData = props => {
    const { children, variant = 'h6', align = 'left', noWrap } = props;

    const { ref, isTruncated } = useIsTruncated();
  
    return (
      <Tooltip title={isTruncated ? children : ''} placement="right" arrow>
      <Typography
        variant={variant}
        ref={ref}
        noWrap={Boolean(noWrap)}
        style={{ width: '100%', color: '#000', padding: 0, margin: 0, textAlign: align }}
      >
        {children}
      </Typography>
      </Tooltip>
    );
  };


  const CircularIndicator = (title, color) => {
    return (
      <Grid container spacing={0}>
        <Grid container item xl={10} lg={10} md={10} sm={10} xs={10}>
          <Box className={classes.topDivScroll}>
            <TypographyData>
              {verifyMaskInquiryNumber(
                inquiry.inquiry?.policeInquiryNumber,
                inquiry.inquiry?.classeDescription,
                allOriginNumbersAreNpu,
              )}
            </TypographyData>
            <TypographyData>
              {inquiry.inquiry.internalSystemNumber
                ? inquiry.inquiry.arquimedesAuto
                  ? `${inquiry.inquiry.internalSystemNumber} ${inquiry.inquiry.arquimedesAuto}`
                  : `${inquiry.inquiry.internalSystemNumber}`
                : 'Número não disponível'}
            </TypographyData>
            <TypographyData>{inquiry.investigated.name}</TypographyData>
            <StepTitle variant="h6" className={classes.labelCard} step={inquiry.step} />
          </Box>
        </Grid>
        <Grid
          item
          xl={2}
          lg={2}
          md={2}
          sm={2}
          xs={2}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'flex-end',
          }}
        >
          <Tooltip arrow title={title} placement="top">
            <FiberManualRecordIcon fontSize="small" style={{ color: color }} />
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Box className={classes.shadow}>
        {inquiry.daysToMovementTaskDeadline
          ? CircularIndicator(
              `Prazo tarefas: ${inquiry.daysToMovementTaskDeadline} dia(s)`,
              getColorForDeadline(inquiry.daysToMovementTaskDeadline),
            )
          : null}
        {inquiry.daysToVictimIntimationDeadline
          ? CircularIndicator(
              `Prazo para envio de documentos a vítima: ${inquiry.daysToVictimIntimationDeadline} dia(s)`,
              getColorForDeadline(inquiry.daysToVictimIntimationDeadline),
            )
          : null}
        {inquiry.pending
          ? CircularIndicator('Com pendência de cumprimento', DEADLINE_COLORS.RED)
          : null}
        {!inquiry.daysToMovementTaskDeadline &&
          !inquiry.daysToVictimIntimationDeadline &&
          !inquiry.pending && (
            <Box className={classes.topDivScroll}>
              <TypographyData>
                {verifyMaskInquiryNumber(
                  inquiry.inquiry?.policeInquiryNumber,
                  inquiry.inquiry?.classeDescription,
                  allOriginNumbersAreNpu,
                )}
              </TypographyData>
              <TypographyData>
                {inquiry.inquiry.internalSystemNumber
                  ? inquiry.inquiry.arquimedesAuto
                    ? `${inquiry.inquiry.internalSystemNumber} ${inquiry.inquiry.arquimedesAuto}`
                    : `${inquiry.inquiry.internalSystemNumber}`
                  : 'Número não disponível'}
              </TypographyData>
              <TypographyData>{inquiry.investigated.name}</TypographyData>
              <StepTitle variant="h6" className={classes.labelCard} step={inquiry.step} />
            </Box>
          )}
        <Divider className={classes.divider} />
        <Grid container spacing={0} style={{ marginBottom: 10 }}>
          <Grid
            container
            item
            xl={10}
            lg={10}
            md={10}
            sm={10}
            xs={10}
            className={classes.divScroll}
          >
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TypographyData>
                CPF do investigado: {inquiry.investigated?.taxpayerRegistry || 'não informado'}
              </TypographyData>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TypographyData>
                RG do Investigado:{' '}
                {inquiry.investigated?.rg !== undefined
                  ? inquiry.investigated?.rg?.code
                  : 'não informado'}
              </TypographyData>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TypographyData>
                {isInfraction(inquiry.inquiry.classe) ? 'Ato Infracional' : 'Crime'} cometido:{' '}
                {inquiry.crimes.length < 1 ? (
                  'Não informado'
                ) : inquiry.crimes.length > 1 ? (
                  <>{inquiry.crimes[0].taxonomy} ...</>
                ) : (
                  inquiry.crimes.map(crime => crime.taxonomy)
                )}
              </TypographyData>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TypographyData>
                Data:{' '}
                <Moment format="DD/MM/YYYY" local>
                  {inquiry.createdAt}
                </Moment>
              </TypographyData>
            </Grid>
          </Grid>
          <Grid
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            {inquiry.isArrested && (
              <Tooltip arrow title="Encontra-se preso" placement="left">
                <Typography variant="body1" className={classes.containerIcon}>
                  <LockPersonIcon fontSize="small" />
                </Typography>
              </Tooltip>
            )}
            <Tooltip
              arrow
              title={inquiry.isProbableNonPersecution ? 'Não persecução' : 'Persecução'}
              placement="left"
            >
              <Typography variant="body1" className={classes.containerType}>
                {inquiry.isProbableNonPersecution ? 'NPP' : 'PP'}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default CardAgreement;
